import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NaacSelectionPage = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://devapi.dbraei.edu.in/api/v1/naacdetails');
        const result = await response.json();
        setOptions(result.rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const navigateToNaacForm = () => {
    navigate('/naacwebdocs');
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Select Document Type
      </Typography>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel>Select Document</InputLabel>
        <Select value={selectedOption} onChange={handleOptionChange} label="Select Document">
          {options.map((option) => (
            <MenuItem key={option.id} value={option.title}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedOption === 'Supporting Documents' && (
        <Button variant="contained" color="primary" onClick={navigateToNaacForm}>
          Go to Supporting Documents
        </Button>
      )}

      {selectedOption === 'File Upload' && (
        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            File upload section here. Implement your file upload logic as needed.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NaacSelectionPage;
