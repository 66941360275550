import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Col, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import Axios from "axios";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Stack } from "@mui/material";
import DropDownData from "../assets/dropdownData.json";

import {
  storageAccount,
  storageContainer,
  storageSAS,
  docViewURL,
} from "../config/Config.js";

const copyToClipboard = (url) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert("Link copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const NaacUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [formData, setFormData] = useState({
    yeardata: "",
    coursedata: "",
    doctitle: "",
  });
  const [listFiles, setListFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const account = storageAccount;
  const sas = storageSAS;
  const containerName = storageContainer;
  const docViewBaseURL = docViewURL;

  const Years_Data = DropDownData.Years_Data;
  const Course_Data = DropDownData.Course_Data;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    fetchFileData();
  }, []);

  const uploadFileToAzure = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    setLoading(true);
    const blobName = `${file.name}`;
    let uploadUrl = `https://${account}.blob.core.windows.net/${containerName}/`;
    if (formData.yeardata) {
      uploadUrl += `${formData.yeardata}/`;
    }
    if (formData.coursedata) {
      uploadUrl += `${formData.coursedata}/`;
    }

    uploadUrl += `${blobName}?${sas}`;

    const config = {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": file.type,
      },
    };

    try {
      const response = await Axios.put(uploadUrl, file, config);
      if (response.status === 201) {
        setUploadStatus("File uploaded successfully!");
        resetForm();
        fetchFileData();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("File upload failed.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const fetchFileData = async () => {
    setLoading(true); // Start loading
    const listBlobsUrl = `https://${account}.blob.core.windows.net/${containerName}?restype=container&comp=list&${sas}`;

    try {
      const response = await Axios.get(listBlobsUrl, {
        headers: {
          "Content-Type": "application/xml",
        },
      });

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "application/xml");

      const blobs = Array.from(xmlDoc.getElementsByTagName("Blob")).map(
        (blob) => {
          const name = blob.getElementsByTagName("Name")[0].textContent;
          return name;
        }
      );

      const filesArray = blobs.map((blobName) => {
        const downloadUrl = `https://${account}.blob.core.windows.net/${containerName}/${blobName}`;
        return {
          Key: blobName,
          Url_link: downloadUrl,
        };
      });

      setListFiles(filesArray);
      setFilteredFiles(filesArray);
    } catch (error) {
      console.error("Error fetching blobs:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const filterFiles = () => {
    const filtered = listFiles.filter((file) => {
      const fileKey = file.Key.toLowerCase();

      if (!formData.yeardata && !formData.coursedata) {
        return !fileKey.includes("/");
      }
      const matchesYear = formData.yeardata
        ? fileKey.includes(formData.yeardata.toLowerCase())
        : true;

      const matchesCourse = formData.coursedata
        ? fileKey.includes(formData.coursedata.toLowerCase())
        : true;
      return matchesYear && matchesCourse;
    });

    setFilteredFiles(filtered);
  };

  const resetForm = () => {
    setFormData({
      yeardata: "",
      coursedata: "",
      doctitle: "",
    });
    setFile(null);
    document.getElementById("file-input").value = null;
  };
  const refreshFiles = () => {
    setFilteredFiles(listFiles);
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Add NAAC Document</h3>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/naacdetails"
            style={{ textDecoration: "none", color: "white" }}
          >
            Add Naac Details for Website
          </Button>
        </div>

        <div className="Dropdown mt-3">
          <Row>
            <Col sm={6} className="mb-3">
              <FormControl fullWidth>
                <InputLabel>Academic Years</InputLabel>
                <Select
                  value={formData.yeardata || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, yeardata: e.target.value })
                  }
                  label="Academic Years"
                >
                  <MenuItem value="">
                    <em>&nbsp;</em>
                  </MenuItem>
                  {Years_Data.map((year) => (
                    <MenuItem key={year.label} value={year.label}>
                      {year.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={6} className="mb-3">
              <FormControl fullWidth>
                <InputLabel>Course</InputLabel>
                <Select
                  value={formData.coursedata || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, coursedata: e.target.value })
                  }
                  label="Course"
                >
                  <MenuItem value="">
                    <em>&nbsp;</em>
                  </MenuItem>
                  {Course_Data.map((course) => (
                    <MenuItem key={course.label} value={course.label}>
                      {course.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={6} className="mb-3">
              <TextField
                label="Document Title"
                value={formData.doctitle}
                onChange={(e) =>
                  setFormData({ ...formData, doctitle: e.target.value })
                }
                fullWidth
              />
            </Col>
            <Col sm={6} className="mb-3">
              <TextField
                id="file-input"
                type="file"
                onChange={handleFileChange}
                fullWidth
                inputProps={{ accept: ".pdf,.doc,.docx,.jpg,.png" }}
              />
            </Col>
          </Row>

          <Stack
            spacing={2}
            direction="row"
            justifyContent="end"
            className="mt-4"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={uploadFileToAzure}
              disabled={loading}
            >
              Upload
            </Button>
            <Button variant="contained" color="secondary" onClick={filterFiles}>
              Get List
            </Button>
            {uploadStatus && <p>{uploadStatus}</p>}
          </Stack>
        </div>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <p>Loading...</p>
        </div>
      ) : (
        <TableContainer className="container my-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">File Name</TableCell>
                <TableCell align="center">Download Link</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles.length > 0 ? (
                filteredFiles.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{file.Key}</TableCell>
                    <TableCell align="center">
                      <a
                        href={docViewBaseURL + containerName + "/" + file.Key}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "#3f51b5",
                          fontWeight: "bold",
                        }}
                      >
                        View Document
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        startIcon={<FileCopyIcon />}
                        onClick={() =>
                          copyToClipboard(
                            `${docViewBaseURL}${containerName}/${file.Key}`
                          )
                        }
                      >
                        Copy Link
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <p>No files found.</p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={refreshFiles}
                    >
                      Refresh
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default NaacUpload;
