import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from "@mui/material";
import { Add, Delete, CloudUpload } from "@mui/icons-material";
import Axios from "axios";
import {
  storageAccount,
  naacStorageContainer,
  storageSAS,
  naacProductionFolder,
  naacDataFolder,
  naacSupportingDocsFolder
} from "../config/Config.js";
import Autocomplete from "@mui/material/Autocomplete";
import { Tooltip } from '@mui/material';

const NaacForm = () => {
  const [criteria, setCriteria] = useState({ value: "", id: "" });
  const [subCriteria, setSubCriteria] = useState({ value: "", id: "" });
  const [loading, setLoading] = useState(false);
  const [levels, setLevels] = useState([
    {
      id: Date.now(),
      Title: "",
      level: "",
      naac_subheading_id: "",
      naac_extended_data_id: "",
      documents: [
        {
          id: Date.now(),
          filename: "",
          file: null,
          pathurl: "",
          pathname: "",
        },
      ],
    },
  ]);
  const [data, setData] = useState([]);
  const [existingData, setExistingData] = useState(null);
  const [levelDropData, setLevelDropData] = useState([]);
  const [deletedDoc, setDeletedDoc] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://devapi.dbraei.edu.in/api/v1/naacdetails/4"
      );
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch updated data.");
    } finally {
      setLoading(false);
    }
  };

  const criteriaOptions =
    data?.naac_extended_headings?.map((heading) => ({
      id: heading.ID,
      value: heading.Heading,
    })) || [];

  const subCriteriaOptions =
    data?.naac_extended_headings
      ?.find((heading) => heading.Heading === criteria.value)
      ?.SubHeading.map((subHeading) => ({
        id: subHeading.ExternalID,
        value: subHeading.Subheading,
      })) || [];

  const handleLevelNameChange = (index, newLevelName) => {
    const updatedLevels = [...levels];
    updatedLevels[index].levelName = newLevelName;
    const selectedLevelDetail = existingData?.find(
      (detail) => detail.level === newLevelName
    );
    if (selectedLevelDetail) {
      updatedLevels[index].Title = selectedLevelDetail.title;
    } else {
      updatedLevels[index].Title = "";
    }

    setLevels(updatedLevels);
  };

  const handleTitleChange = (index, newTitle) => {
    const updatedLevels = [...levels];
    updatedLevels[index].Title = newTitle;
    setLevels(updatedLevels);
  };

  const addDocument = (levelIndex) => {
    const updatedLevels = [...levels];
    updatedLevels[levelIndex].documents.push({
      file: null,
      uploadDisabled: false,
    });
    setLevels(updatedLevels);
  };
  const addLevel = () => {
    setLevels([
      ...levels,
      {
        levelTitle: "",
        documents: [
          {
            file: null,
            uploadDisabled: false,
          },
        ],
      },
    ]);
  };

  const deleteLevel = (index) => {
    setLevels((prevLevels) => prevLevels.filter((_, idx) => idx !== index));
  };

  const deleteDocument = async (levelIndex, docIndex, docId) => {
    try {
      await handleDeleteFile(docId);
      setLevels((prevLevels) => {
        const updatedLevels = [...prevLevels];
        if (updatedLevels[levelIndex]?.documents) {
          updatedLevels[levelIndex].documents.splice(docIndex, 1);
        }
        return updatedLevels;
      });
      if (existingData) {
        const updatedExistingData = existingData.map((level) => ({
          ...level,
          documents: level.documents?.filter((doc) => doc.ID !== docId) || [],
        }));
        setExistingData(updatedExistingData);
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

const handleDeleteFile = async (docId) => {
  try {
      const deleteresponse = await fetch(`https://devapi.dbraei.edu.in/api/v1/naacdetails/${docId}`, {
          method: "DELETE"
      });
      if (!deleteresponse.ok) {
          throw new Error(`Error deleting file: ${deleteresponse.statusText}`);
      }
      console.log("File deleted successfully:", deleteresponse);
      setDeletedDoc((prev) => [...prev, docId]);
    } catch (error) {
      console.error("Error deleting file:", error);
  }
};

console.log("existingData:", criteria, subCriteria);

  const uploadFileToAzure = async (file) => {
    if (!file) return null;
    const blobName = file.name;
    const uploadUrl = `https://${storageAccount}.blob.core.windows.net/${naacStorageContainer}/${naacProductionFolder}/${naacDataFolder}/${naacSupportingDocsFolder}/${criteria.value}/${subCriteria.value}/${blobName}?${storageSAS}`;
    const config = {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": file.type,
      },
    };
    try {
      const response = await Axios.put(uploadUrl, file, config);
      return response.status === 201 ? uploadUrl : null;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!criteria.id || !subCriteria.id) {
      alert("Please select both criteria and sub-criteria.");
      return;
    }
    for (const level of levels) {
      for (const document of level.documents) {
        if (!document.filename?.trim()) {
          alert("Each document must have a valid filename.");
          return;
        }
        if (!document.file) {
          alert("Please upload a file for each document.");
          return;
        }
      }
    }
    setLoading(true);
    try {
        const updatedLevels = await Promise.all(
            levels.map(async (level) => {
                const updatedDocuments = await Promise.all(
                    level.documents.map(async (doc) => {
                        if (doc.file) {
                            const url = await uploadFileToAzure(doc.file);
                            return {
                                ...doc,
                                pathurl: url,
                                pathname: url ? doc.filename : "",
                            };
                        }
                        return doc;
                       })
                );
                return { ...level, documents: updatedDocuments };
            })
        );
        const postData = updatedLevels.map((level) => {
            const selectedLevelDetail = existingData?.find((detail) => {
                return (
                    (detail.level?.trim().toLowerCase() === level.level?.trim().toLowerCase()) || false
                );
            });
            return {
                Title: level.Title,
                level: level.levelName,
                naac_subheading_id: subCriteria.id || null,
                naac_extended_data_id: selectedLevelDetail?.naacExtendedId || null,
                files: level.documents.map((doc) => ({
                    ID: Math.floor(Math.random() * 1000000),
                    filename: doc.filename || "",
                    pathname: doc.pathname || "",
                    pathurl: doc.pathurl || "",
                })),
            };
        });

        const response = await fetch(
            `https://devapi.dbraei.edu.in/api/v1/naacdetails/4?subheading=${subCriteria.id}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(postData),
            }
        );

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        alert("Data submitted successfully!");
        setLevels([
          {
          id: Date.now(),
          Title: "",
          level: "",
          naac_subheading_id: "",
          naac_extended_data_id: "",
          documents: [
            {
              id: Date.now(),
              filename: "",
              file: null,
              pathurl: "",
              pathname: "",
            },
          ],
        },]);
        setCriteria('');
        setSubCriteria('');
        setExistingData(null)
    } catch (error) {
        console.error("Submission Error:", error.message);
        alert("Failed to submit data.");
    } finally {
        setLoading(false);
    }
};

  const handleCriteriaChange = (e) => {
    const selectedOption = criteriaOptions.find(
      (option) => option.value === e.target.value
    );
    setCriteria({ value: selectedOption.value, id: selectedOption.id });
    setSubCriteria({ value: "", id: "" });
    setLevels([
      {
        id: Date.now(),
        Title: "",
        level: "",
        naac_subheading_id: "",
        naac_extended_data_id: "",
        documents: [
          {
            id: Date.now(),
            filename: "",
            file: null,
            pathurl: "",
            pathname: "",
          },
        ],
      },
    ]);
  };

  const handleSubCriteriaChange = async (e) => {
    const selectedOption = subCriteriaOptions.find(
      (option) => option.value === e.target.value
    );
    setSubCriteria({ value: selectedOption.value, id: selectedOption.id });

    try {
      const response = await Axios.get(
        `https://devapi.dbraei.edu.in/api/v1/naacdetails/4?subheading=${selectedOption.id}`
      );
      if (
        response.data.NaacExtended_Data &&
        response.data.NaacExtended_Data.length > 0
      ) {
        const existingLevels = response.data.NaacExtended_Data.map(
          (levelKey) => {
            const levelEntries = Object.entries(levelKey.URLs);
            const levelsWithDetails = levelEntries.map(
              ([levelName, levelData]) => {
                const documents = levelData.files.map(
                  ({ ID, filename, pathurl, pathname }) => ({
                    ID,
                    filename,
                    file: null,
                    pathurl,
                    pathname,
                  })
                );
                return {
                  level: levelName,
                  title: levelData.Title,
                  naacExtendedId: levelData.naac_extended_data_id,
                  documents,
                };
              }
            );

            return levelsWithDetails;
          }
        );

        const flattenedLevels = existingLevels.flat();
        setExistingData(flattenedLevels);
        setLevelDropData(flattenedLevels.map((level) => level.level));
        setLevels([
          {
            id: Date.now(),
            Title: "",
            levelName: "",
            naac_subheading_id: "",
            naac_extended_data_id: "",
            documents: [
              {
                id: Date.now(),
                filename: "",
                file: null,
                pathurl: "",
                pathname: "",
              },
            ],
          },
        ]);
      } else {
        setLevels([
          {
            ID: Date.now(),
            level: "level1",
            title: "",
            documents: [
              {
                ID: Date.now(),
                filename: "",
                pathurl: "",
                pathname: "",
              },
            ],
          },
        ]);
        setExistingData(null);
      }
    } catch (error) {
      console.error("Error fetching subheading data:", error);
    }
  };
  const handleDocumentChange = (levelIndex, docIndex, field, value) => {
    setLevels((prevLevels) => {
      const updatedLevels = [...prevLevels];
      if (field === "file" && value instanceof File) {
        updatedLevels[levelIndex].documents[docIndex].filename = value.name;
        updatedLevels[levelIndex].documents[docIndex].file = value;
      } else {
        updatedLevels[levelIndex].documents[docIndex][field] = value;
      }
      return updatedLevels;
    });
  };

  return (
    <Box sx={{ maxWidth: 900, margin: "0 auto", p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Create NAAC Document Data
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Criteria</InputLabel>
          <Select
            value={criteria.value}
            onChange={handleCriteriaChange}
            label="Criteria"
          >
            {criteriaOptions.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Sub Criteria</InputLabel>
          <Select
            value={subCriteria.value}
            onChange={handleSubCriteriaChange}
            label="Sub Criteria"
          >
            {subCriteriaOptions.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {levels.map((level, levelIndex) => (
          <Box
            key={level.id}
            sx={{ mb: 4, border: "1px solid #ccc", p: 2, borderRadius: 2 }}
          >
            <Autocomplete
              freeSolo
              options={levelDropData}
              value={level.levelName}
              onChange={(event, newValue) =>
                handleLevelNameChange(levelIndex, newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Level`}
                  fullWidth
                  onChange={(e) => {
                    const valueWithoutSpaces = e.target.value.replace(/\s+/g, '');
                    handleLevelNameChange(levelIndex, valueWithoutSpaces);
                }}
                  sx={{ mt: 2 }}
                />
                
              )}
            />

            <TextField
              label="Title"
              fullWidth
              value={level.Title}
              onChange={(e) => handleTitleChange(levelIndex, e.target.value)}
              sx={{ mt: 2 }}
              disabled={existingData?.some(
                (detail) =>
                  detail.level?.trim().toLowerCase() ===
                  level.levelName?.trim().toLowerCase()
              )}
            />
            <Box sx={{ mt: 2 }}>
              {level.documents.map((doc, docIndex) => (
                <Grid style={{ display: "flex", alignItems: "center" }} container spacing={2} key={doc.id}>
                  <Grid item xs={4}>
                    <TextField
                      label="Filename"
                      fullWidth
                      value={doc.filename}
                      onChange={(e) =>
                        handleDocumentChange(
                          levelIndex,
                          docIndex,
                          "filename",
                          e.target.value
                        )
                      }
                    />
                    {!doc.file && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ mt: 1 }}
                      >
                        Please select a file before entering the filename.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <input
                      accept="*"
                      style={{ display: "none" }}
                      id={`file-upload-${levelIndex}-${docIndex}`}
                      type="file"
                      onChange={(e) =>
                        handleDocumentChange(
                          levelIndex,
                          docIndex,
                          "file",
                          e.target.files[0]
                        )
                      }
                    />
                    <label htmlFor={`file-upload-${levelIndex}-${docIndex}`}>
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUpload />}
                      >
                        Select File
                      </Button>
                    </label>
                    {doc.file && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Selected File: {doc.file.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      color="primary"
                      onClick={() => deleteDocument(levelIndex, docIndex)}
                      sx={{ ml: 1 }}
                      >
                      <IconButton color="error">
                        <Delete />
                      </IconButton>
                      Delete form
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => addDocument(levelIndex)}
              >
                Add New Document
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={addLevel}
                sx={{ m: 3 }}
              >
                Add other Level
              </Button>
            </Box>
            <Box sx={{ textAlign: "center", my: 2 }}>
              <Button color="error" onClick={() => deleteLevel(levelIndex)}>
                <IconButton>
                  <Delete />
                </IconButton>
                Delete Level
              </Button>
            </Box>
          </Box>
        ))}
        {levels.length === 0 && (
          <Box sx={{ textAlign: "center", my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={addLevel}
            >
              Add Level
            </Button>
          </Box>
        )}
        <Button
          variant="contained"
          color="secondary"
          sx={{ my: 5 }}
          type="submit"
        >
          {loading ? <CircularProgress size={24} /> : "Submit Documents"}
        </Button>

        {existingData && (
         <TableContainer component={Paper} sx={{ mt: 4, boxShadow: 3 }}>
           <Table>
             <TableHead>
               <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                 <TableCell sx={{ fontWeight: 'bold' }}>Document Title</TableCell>
                 <TableCell sx={{ fontWeight: 'bold' }}>Filename</TableCell>
                 <TableCell sx={{ fontWeight: 'bold' }}>Path URL</TableCell>
                 <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {existingData && existingData.length > 0 ? (
                 existingData.map((level, levelIndex) => (
                   <React.Fragment key={levelIndex}>
                     {level.documents?.map((doc, docIndex) => (
                       <TableRow
                         key={docIndex}
                         sx={{
                           backgroundColor: deletedDoc.includes(doc.ID)
                             ? "lightgray"
                             : "white",
                           opacity: deletedDoc.includes(doc.ID) ? 0.5 : 1,
                           pointerEvents: deletedDoc.includes(doc.ID) ? "none" : "auto",
                           '&:hover': {
                             backgroundColor: deletedDoc.includes(doc.ID)
                               ? "lightgray"
                               : "#f1f1f1",
                           },
                         }}
                       >
                         <TableCell><Tooltip title={level.title} arrow>
                          {level.title.length > 50 ? level.title.slice(0, 50) + '...' : level.title}
                          </Tooltip>
                          </TableCell>
                         <TableCell>
                         <Tooltip title={doc.filename} arrow>
                             {doc.filename.length > 50 ? doc.filename.slice(0, 50) + '...' : doc.filename}
                           </Tooltip>
                         </TableCell>
                         <TableCell>
                           <Tooltip title={doc.pathurl} arrow>
                             <a
                               href={doc.pathurl}
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{ textDecoration: 'none', color: '#1976d2' }}
                             >
                               {doc.pathurl.length > 50 ? doc.pathurl.slice(0, 20) + '...' : doc.pathurl}
                             </a>
                           </Tooltip>
                         </TableCell>
                         <TableCell>
                          <>
                          <Button
                             variant="contained"
                             color="primary"
                             sx={{ textTransform: 'none' }}
                             href={doc.pathurl}
                             target="_blank"
                           >
                             View/Download
                           </Button>
                           <Button
                             variant="text"
                             color="error"
                           >
                           <IconButton
                           color="error"
                             sx={{
                               ml: 1,
                               '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.08)' },
                             }}
                             onClick={() => (deleteDocument(levelIndex, docIndex, doc.ID))}
                           >
                             <Delete />
                           </IconButton>
                           Delete File
                           </Button>
                          </>                           
                         </TableCell>
                       </TableRow>
                     ))}
                   </React.Fragment>
                 ))
               ) : (
                 <TableRow>
                   <TableCell colSpan={6} sx={{ textAlign: 'center', fontStyle: 'italic', color: 'gray' }}>
                     No existing data available
                   </TableCell>
                 </TableRow>
               )}
             </TableBody>
           </Table>
         </TableContainer>
         
        
        )}
      </form>
    </Box>
  );
};

export default NaacForm;
